body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* --primary-color:  rgb(64 71 86); */
  --primary-color:  rgb(52 58 70);
  --secondary-color: rgb(35 39 47);
  --third-color: rgb(35 39 47);
  --white-color: rgb(235 236 240);
}

.darkBackground {
  background-color: var(--primary-color);
}

.darkerBackground {
  background-color: var(--secondary-color);
}

.rounded-2xl {
  border-radius: 1rem;
}

.text-white {
  color: var(--white-color);
}

.span-button {
  width: 66.66%;
  box-sizing: border-box; 
  text-align: center;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.boarder-white {
  border: 2px solid white;
}